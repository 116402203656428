@media only screen and (max-width: 979px){
    .is-sticky .navbar-collapse .nav-item{
        color: black;
            
        }
        .is-sticky .navbar-collapse {
            background-color: white;
            padding:10px 0px;
            
        }
    
        .rr .navbar-collapse .nav-item .nav-link{
            color: black!important;
                
            }
            .rr .navbar-collapse {
                background-color: white;
                padding:10px 0px;
                
            }
            .c-navbar .container-fluid{
                background-color: white;
            }
            .rr{
                position:static!important;
            }
            .is-sticky{
                position:static!important;
            }
           .nav-link{
            display: inline-block;
           }
           .nav-item a:hover::after{
            left: 5px!important;
           }
}

@media only screen and (max-width: 768px) {
   .c-testimonial::after{
    width: 100%!important;
   }
   .headings h1 {
    font-size: 28px!important;
   }
   .home-blob{
    width: 90%!important;
    text-align: center!important;
    
   }
   .home-blob2{
    width: 90%!important;
    text-align: center!important;
    
   }
   /* .slideshow{
    height: 87vh!important;
   } */
   .is-sticky .navbar-collapse .nav-item{
    color: black;
        
    }
    .is-sticky .navbar-collapse {
        background-color: white;
        padding:10px 0px;
        
    }

    .rr .navbar-collapse .nav-item .nav-link{
        color: black!important;
            
        }
        .rr .navbar-collapse {
            background-color: white;
            padding:10px 0px;
            
        }
        .c-navbar .container-fluid{
            background-color: white;
        }
       
        .rr{
            position:static!important;
        }
        .is-sticky{
            position:static!important;
        }

        .nav-link{
            display: inline-block;
           }
           .nav-item a:hover::after{
            left: 5px!important;
           }
   

  }
*{ font-family: 'Montserrat', sans-serif!important;}
.cursive{
    font-family: 'Dancing Script', cursive!important;
}
.fs-7{
    font-size: 12px!important;
}
.mon{
   
font-family: 'Montserrat', sans-serif;
}
.bg-w{
    background-color: white!important;
}
.bg-grey{
    background-color: #F9F9F9!important;
}
.bg-white{
    background-color: white!important;
}
.bg-primary{
    background-color: var(--primary-color)!important;
}

.section{
    position: relative;
    width: 100%;
    margin: 60px 0;
    padding: 60px 0;
    background-color: #F9F9F9;
    overflow: hidden;
}

.headings{
    text-align: center;
}


.home-bg{
    background-position: center;
    background-size: cover;
    height: 86vh;
    background-repeat: no-repeat;
}
.home-blob {
    position: absolute;

    left: 50%;
    z-index: 12;
    color: white;
    transform: translate(-50%,-50%);
    background: var(--primary-color);
    width: 60%;
    padding: 45px 40px;
    border-radius: 6px;
}
.home-blob2 {
    position: relative;

    left: 50%;
    z-index: 12;
    color: white;
    transform: translate(-50%,-50%);
    background: var(--primary-color);
    width: 60%;
    padding: 45px 40px;
    border-radius: 6px;
}
.home-bg::after{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}
.home-title{
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
    position: relative;
}
.home-title h1,p{
    color: white;
}
.home-title h1{
font-size: 55px;
}
.home-title p{
    font-size: 18px;
    }
.week img{
    object-fit: cover;
    height: 100%;
    width: 100%;

}
.headings h1{
    position: relative;
    font-size: 40px;
    display: inline-block;
    z-index: 11;
}
.c-line::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: var(--primary-color);
    top: 0;
    left: 0px;

}
.c-overlay::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
}
.b-line::after{
    content: "";
    position: absolute;
    height: 3px;
    width: 70%;
    background-color: var(--primary-color);
    bottom: -12px;
    left: 50%;
    transform: translate(-50%,50%);

}

.headings p{
    font-size: 20px;
    position: relative;
    z-index: 11;
}
.weektitle{
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    transform: translate(20%,-50%);
    /* width: 100%; */
    z-index: 1;
}

.c-blob::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: 1;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

/* -----------------test------------ */

.whychoose img{
    height: 52px;
    width: 52px;
    object-fit: cover;
    object-position: center;
    margin: auto;    height: 90px;
    width: 90px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    border: 2px dotted red;
    border-radius: 50%;
    /* margin: 20px; */
    padding: 20px;
    overflow: inherit;
}

.c-testimonial::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    width: 65%;
    height: 100%;
    
}
.test h1,p{
    position: relative;
    z-index: 11;
    color: white;
}
.c-testimonial .container{
    z-index: 1;
    position: relative;
}


/* -----------------test-end------------ */
.home-title2{
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    z-index: 17;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
}

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: black;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  