.c-navbar{
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    color: white;
    z-index: 112;
    font-weight: 500;
}
/* .c-navbar::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: -1;
} */

.nav-link{
    color: white;
}
.nav-item a:hover::after{
    content: "";
    /* color: white; */
    background-color: red;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    /* border-bottom: 3px solid var(--primary-color); */
    left: 0;

}


.c-navbar .nav-link{
    color: white;
}
.nav-item a{
  transition: 0.3s all;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
  position: relative;
}
.is-sticky {
    background-color: white;
    height: 75px;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 12121212;
    transition: 0.3s all;

  }
 
  .rr{
    
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    /* background-color: red !important; */
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    transition: 0.3s all;
   
  }
  .nav-link{
    color: white;
  }
  .rr .nav-link {
    color: white!important;
}
.is-sticky .nav-link {
    color: black!important;
}




/* .rr .rr .navbar-collapse .nav-item{
    color: black;
    
}
.navbar-collapse .nav-item{
    color: black;
    
} 

.is-sticky .navbar-collapse .nav-item{
  
    color: black;
    
}
.navbar-collapse .nav-item .nav-link{
  
    color: black!;
    
} */

.navbar-toggler-icon{
    background-image: url('../images/hh1.png');
    
    width: 2.5rem;
    height: 2.5rem;
}
.navbar-toggler,.navbar-toggler:focus{
    border: none;
    outline: none!important;
    box-shadow: none;
}

.c-navs{
position: fixed;
bottom: 0.4rem;
border-radius: 1.5rem;
background-color: black;
transform: translate(-50%,-50%);
color: white;
display: flex;
gap: 10px;
left: 50%;
-webkit-backdrop-filter: blur(25px);
backdrop-filter: blur(25px);
background: rgba(0,0,0,.3);
z-index: 111;

}
.c-navs div{
    font-size: 12px;
    color: white;
    padding: 10px 10px;
}
.c-navs div img{
    height: 40px;
    width: 40px;
    padding: 9px;
}

.c-navs div img:hover{
    background-color: red;
    border-radius: 50%;
   
}
.n-logo{
position: relative;
z-index: 1212;

}
.n-logo span{
display: inline-block;
position: relative;
z-index: 1223213;

}
.n-logo span:first-child:before{
    content: "";
   background-color: var(--primary-color);
    position: absolute;
    display: inline-block;
    height: 120px;
    width: 120px;
    

    z-index: -1;
    top: 50%;
    transform: translate(0%,-50%);
    border-radius: 50%;

   

}
.n-logo span:first-child{
 font-size: 50px;
 color: white;
 text-shadow: 0 1px 0 #ccc,
              0 2px 0 #c9c9c9,
              0 3px 0 #bbb,
              0 4px 0 #b9b9b9,
              0 5px 0 #aaa,
              0 6px 1px rgba(0,0,0,.1),
              0 0 5px rgba(0,0,0,.1),
              0 1px 3px rgba(0,0,0,.3),
              0 3px 5px rgba(0,0,0,.2),
              0 5px 10px rgba(0,0,0,.25),
              0 10px 10px rgba(0,0,0,.2),
              0 20px 20px rgba(0,0,0,.15);
}
.n-logo span:last-child{
    letter-spacing: 10px;
}
.home-title3{
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    z-index: 17;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
}

/*   
  @keyframes animName {
   0%{
      transform: rotate(360deg);
     }
  100%{
      transform: rotate(0deg);
     }
  }
  
  .world{
    animation: animName 7s linear infinite;
    position: absolute;

    width: 100%;
    object-fit: contain;
    object-position: center;
    left: 0;
  
    top: -30px;
    z-index: -1;
   

  }
  
   */