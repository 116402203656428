.itenary-heading img{
height: 20px;
width: 20px;
margin-top: 5px;
}
.task-card{
display: flex;
gap: 20px;
flex-wrap: wrap;
align-items: center;
}
.task-card img{
    height: 25px;
    width: 25px;
}
.task-card small{
    font-weight: 500;
}
.itenary-btn{
display: flex;
gap: 20px;
align-items: center;
/* flex-wrap: wrap; */

}
.itenary-btn img{
    height: 20px;
    width: 20px;
}
.itenary-btn div{

    
    
   
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background: var(--primary-color);
    color: white;
}
.nav-tabs .nav-link{
    font-weight: 500;
    color: black;
}
.accordion-button:not(.collapsed){
    background-color: black;
    color: white;
    box-shadow: none;
}
.itenary-list {
   
}
.itenary-list li{
    margin-bottom: 15px;
     /* display: flex;
     flex-wrap: wrap;
     gap: 10px;
     align-items: center; */
}
.tab-content>.active{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.itenary-card{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}


/* --------------- */


.c-tab>.active{
   padding: 20px 15px;
}
.blob h1{
    position: relative;
    z-index: 1;
}
/* .home-title h1::before{
    position: absolute;
    content: "";
    top: -15px;
    left: -62px;
    background-color: red;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transform: translate(50%,0%);
    z-index: -1;
    
} */
.blob h1::before{
    position: absolute;
    content: "";
    top: -9px;
    left: -50px;

    background-color: red;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transform: translate(50%,0%);
    z-index: -1;
}
/* .accordion-button{
    background-color: white;
    color: black;
} */

.accordion-button::after{
    background-image: url('../images//down-arrow.png')
}
.accordion-button:not(.collapsed)::after{
    background-image: url('../images//up-arrow.png')
}
.dates{
    display: flex;
    gap: 20;
    flex-wrap: wrap;
    /* justify-content: center; */
    list-style-type: none;
padding: 0;
    margin: 0;
}


.dates li{
display: inline-flex;
gap: 10px;
align-items: center;
margin-bottom: 10px;
background-color: black;
color: white;
padding: 10px;
border-radius: 8px;
margin-right: 10px;

}
.dates li:last-child{
    margin-bottom: 0;
}
.dates li img{
height: 20px;
width: 20px;
padding: 1px;
}