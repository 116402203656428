.contact-icon{

}
.contact-img{
    background: var(--primary-color);
    display: flex;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 10px;
}
.contact-icon a{
    text-decoration: none;
}
.contact-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.contact-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    padding: 20px 0px;
    font-size: 14px;
}
.gg::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: black;
    

}
.gg .container{
    position: relative;
    z-index: 1;
}
.imgg img{
   height: 60px;
   width: 60px;
   border-radius: 50%;
   padding: 12px;
   background-color: var(--primary-color);
}

.imgg{

    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    top: 0;
    background-color: white;

}
.cccc{
    position: relative;
    padding: 50px 0px;
    /* margin: 0 10px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}