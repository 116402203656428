
:root {
  --primary-color: #c42218!important;
  --animate-delay: 0.5s;

  
}

.primary-color{
  color: var(--primary-color)!important;
}

